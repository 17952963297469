import { Grid, Typography } from "@mui/material";
import { LicenseDetails as LicenseDetailsType } from "../api/Licenses";

type Props = {
    licenseDetails: LicenseDetailsType;
}

export const LicenseDetails = ({ licenseDetails }: Props): JSX.Element => (
    <Grid container>
        <Grid item xs={5}>
            <Typography>{licenseDetails.application.name}</Typography>
        </Grid>
        <Grid item xs={5}>
            <Typography>{licenseDetails.value}</Typography>
        </Grid>
        <Grid item xs={2}>
            <Typography>{licenseDetails.description}</Typography>
        </Grid>
    </Grid>
);