import AddCardIcon from '@mui/icons-material/AddCard';
import { Button, Container, Paper, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import { Application, getApplications as getApplicationsApi } from '../api/Applications';
import { Client, getClients as getClientsApi } from '../api/Clients';
import { deleteLicense as deleteLicenseApi, getLicenses as getLicensesApi, License, LicenseDetails } from '../api/Licenses';
import { DefaultDialog } from '../components/dialogs/DefaultDialog';
import { AddLicenseForm } from '../components/forms/LicenseForm';
import { LicenseTable } from '../components/LicenseTable';
import { partition } from '../utils/ArrayUtils';

export const Licenses = (): JSX.Element => {
    const [licenses, _setLicenses] = useState<License[]>([]);
    const [applications, setApplications] = useState<Map<Application["id"], Application>>(new Map());
    const [clients, setClients] = useState<Map<Client["id"], Client>>(new Map());
    const [open, setOpen] = useState(false);
    const [editLicense, setEditLicense] = useState<License>(null);
    const confirm = useConfirm();

    useEffect(() => {
        Promise.all([
            getLicensesApi(),
            getApplicationsApi(),
            getClientsApi()
        ]).then(([licensesRes, applicationsRes, clientsRes]) => {
            const clientMap = new Map(clientsRes.data
                .sort((a, b) => a.fullName.localeCompare(b.fullName))
                .map((c) => [c.id, c]));

            setClients(clientMap);
            setApplications(new Map(applicationsRes.data.map((a) => [a.id, a])));
            setLicenses(clientMap, licensesRes.data);
        });
    }, []);

    const setLicenses = (clientMap: Map<number, Client>, licenses: License[]): void => {
        _setLicenses(licenses.sort((a, b) => clientMap.get(a.clientId).fullName.localeCompare(clientMap.get(b.clientId).fullName)))
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setEditLicense(null);
        setOpen(false);
    };

    const deleteUnusedLicenses = (licenses: License[]): Promise<unknown> => {
        return deleteLicenses(licenses);
    };

    const deleteActiveLicenses = async (licenses: License[]): Promise<unknown> => {
        await confirm({ title: `Delete ${licenses.length > 1 ? "licenses" : "license"}`, description: `Are you sure you want to delete ${licenses.length > 1 ? 'these licenses' : "this license"}? The client won't able to use the application anymore.` });
        return await deleteLicenses(licenses);
    };

    const deleteLicenses = (deleteLicenses: License[]): Promise<unknown> => {
        return new Promise((resolve, reject) => {
            const promises: Promise<unknown>[] = [];

            deleteLicenses.forEach((license) => {
                promises.push(deleteLicenseApi(license.id));
            });

            Promise.all(promises)
                .then(() => {
                    setLicenses(clients, licenses.filter((license) => !deleteLicenses.includes(license)));
                })
                .then(resolve)
                .catch(reject);
        });
    };

    const LicenseAdded = (license: LicenseDetails) => {
        setLicenses(clients, [...licenses, license]);
        setEditLicense(license);
    };

    const LicenseUpdated = (updatedLicense: LicenseDetails) => {
        const index = licenses.findIndex(license => license.id === updatedLicense.id);

        if (~index) {
            if (editLicense == licenses[index]) {
                setEditLicense(updatedLicense);
            }
            licenses[index] = updatedLicense;
            setLicenses(clients, [...licenses]);
        }
    };

    const ShowEditLicense = (license: License) => {
        setEditLicense(license);
        setOpen(true);
    };

    const [activeLicenses, unusedLicenses] = partition(licenses, (license) => license.machineId !== null);

    return <>
        <Container maxWidth="lg" sx={{ my: 4 }}>
            {unusedLicenses?.length > 0 &&
                <Paper elevation={3} sx={{ p: 2, my: 2 }}>
                    <LicenseTable
                        title="Unused Licenses"
                        licenses={unusedLicenses}
                        clients={clients}
                        onDelete={deleteUnusedLicenses}
                        onEdit={ShowEditLicense}
                    />
                </Paper>
            }

            <Paper elevation={3} sx={{ p: 2, my: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    m={2}
                >
                    <Typography component="h2" variant="h6" color="primary">
                        Active Licenses
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<AddCardIcon />}
                        onClick={handleOpen}
                    >
                        Add License
                    </Button>
                </Stack>

                <LicenseTable
                    title="Active Licenses"
                    licenses={activeLicenses}
                    clients={clients}
                    onDelete={deleteActiveLicenses}
                    onEdit={ShowEditLicense}
                />
            </Paper>

            <DefaultDialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                title={editLicense ? <>Edit license from <strong>{clients.get(editLicense.clientId).fullName}</strong></> : "New license"}>
                <DialogContent>
                    <AddLicenseForm
                        applicationsMap={applications}
                        clientsMap={clients}
                        editLicense={editLicense}
                        onLicenseAdded={LicenseAdded}
                        onLicenseUpdated={LicenseUpdated}
                    />
                </DialogContent>
            </DefaultDialog>
        </Container>
    </>
}