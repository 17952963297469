import axios, { AxiosResponse } from "axios";
import { DefaultModel } from "./DefaultModel";

const url = "applications";

export interface Application extends DefaultModel {
    id: string;
    name: string;
    publicKey: string;
}

export const getApplications = (): Promise<AxiosResponse<Application[]>> =>
    axios.get<Application[]>(url);

export const addApplication = (appDetails: Application): Promise<AxiosResponse<Application>> =>
    axios.post<Application>(url, appDetails);