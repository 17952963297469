import { FormControl, InputLabel, FormHelperText, TextField, Grid, Button, Typography, Select, MenuItem } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ClientDetails, getClientDetails } from '../../api/Clients';

const countries = ["Belgium", "England", "France", "Germany", "Luxembourg", "Netherlands"];

type FormValues = ClientDetails;
type Props = {
    editClientId?: number;
    onSubmit: (client: ClientDetails) => void;
};

const defaults: ClientDetails = {
    fullName: "",
    name: "",
    department: "",
    address: {
        streetAddress: "",
        postalCode: "",
        city: "",
        country: "Netherlands"
    },
    licenses: []
};

export const AddClientForm = ({ editClientId, onSubmit }: Props): JSX.Element => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ defaultValues: defaults });

    useEffect(() => {
        if (editClientId != null) {
            getClientDetails(editClientId)
                .then((res) => reset(res.data))
                .catch(() => console.error("Cannot edit client, failed to retrieve client details"));
        }
        else {
            reset();
        }
    }, [editClientId]);

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
            <Grid item xs={7} >
                <FormControl fullWidth>
                    <Controller
                        control={control}
                        name="name"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                label="Name *"
                                size="small"
                                error={!!errors.name}
                                {...field} />
                        )}
                    />
                    {errors.name?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid item xs={7} >
                <FormControl fullWidth>
                    <Controller
                        control={control}
                        name="department"
                        render={({ field }) => (
                            <TextField
                                label="Department"
                                size="small"
                                {...field} />
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <Typography component="h3" variant="h6" mt={2} ml={1}>
                    Address
                </Typography>
            </Grid>

            <Grid item xs={7}>
                <FormControl fullWidth>
                    <Controller
                        control={control}
                        name="address.streetAddress"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                label="Street Address *"
                                size="small"
                                error={!!errors.address?.streetAddress}
                                {...field} />
                        )}
                    />
                    {errors.address?.streetAddress?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid item sm={7}>
                <FormControl fullWidth>
                    <Controller
                        control={control}
                        name="address.postalCode"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                label="Postal Code *"
                                size="small"
                                error={!!errors.address?.postalCode}
                                {...field} />
                        )}
                    />
                    {errors.address?.postalCode?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid item xs={7}>
                <FormControl fullWidth>
                    <Controller
                        control={control}
                        name="address.city"
                        rules={{ required: true }}
                        render={({ field: { onChange, ...restField } }) => (
                            <TextField
                                label="City *"
                                size="small"
                                error={!!errors.address?.city}
                                onChange={(e) => onChange(e.target.value)}
                                {...restField} />
                        )}
                    />
                    {errors.address?.city?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid item xs={6}>
                <FormControl size="small" fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Controller
                        control={control}
                        name="address.country"
                        rules={{ required: true }}
                        render={({ field: { onChange, ...restField } }) => (
                            <Select
                                label="Country"
                                onChange={(e) => onChange(e.target.value)}
                                {...restField}
                            >
                                {countries.map((country) =>
                                    <MenuItem key={country} value={country}>{country}</MenuItem>
                                )}
                            </Select>
                        )}
                    />
                </FormControl>
            </Grid>

            <Grid container item justifyContent="flex-end" xs={12}>
                <Button type="submit" variant="outlined">Submit</Button>
            </Grid>
        </Grid>
    </form>
}