import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AssignToIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Card, CardHeader, CardMedia, Chip, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Typography } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { Composition, CompositionChannel } from '../api/SingularCompositions';

type Props = {
    composition: Composition;
    onEnable(composition: Composition, enabled: boolean): void;
    onEdit(composition: Composition): void;
    onAssign(composition: Composition): void;
    onDelete(composition: Composition): void;
}

const dimOnTrue = (flag: boolean): Partial<CSSProperties> => {
    return {
        opacity: flag ? 0.75 : 1,
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }
}

export const CompositionCard = ({ composition, onEnable, onEdit, onAssign, onDelete }: Props): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: React.MouseEvent) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onEnable(composition, event.target.checked);
    };

    const handleEditClick = () => {
        setAnchorEl(null);
        onEdit(composition);
    }

    const handleAssignTo = () => {
        setAnchorEl(null);
        onAssign(composition);
    }

    const handleDeleteClick = () => {
        setAnchorEl(null);
        onDelete(composition);
    }

    return <>
        <Card elevation={3} style={dimOnTrue(!composition.enabled)}>
            <CardHeader
                title={
                    <div>
                        <Switch
                            size="small"
                            edge="start"
                            checked={composition.enabled}
                            onChange={handleEnabledChange} />
                        <br />
                        {composition.name}
                    </div>
                }
                titleTypographyProps={{ variant: "subtitle1" }}
                subheader={
                    <div>
                        Composition ID: {composition.compositionId ?? ""}
                        <br />
                        App Template ID: {composition.compositionAppTemplateId ?? ""}
                    </div>
                }
                subheaderTypographyProps={{ variant: "subtitle2" }}
                sx={{ pb: "0" }}
                action={
                    <>
                        <IconButton
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={handleEditClick}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </MenuItem>
                            <Divider />

                            {composition.channel == CompositionChannel.Private &&
                                <MenuItem onClick={handleAssignTo}>
                                    <ListItemIcon>
                                        <AssignToIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Assign to" />
                                </MenuItem>
                            }

                            <MenuItem onClick={handleDeleteClick}>
                                <ListItemIcon>
                                    <DeleteForeverIcon />
                                </ListItemIcon>
                                <ListItemText primary="Delete" />
                            </MenuItem>
                        </Menu>
                    </>
                }
            />
            <Box sx={{
                mx: "0.5rem",
                mb: "0.5rem"
            }}>
                {composition.channel == CompositionChannel.Private &&
                    <Box sx={{
                        mx: "0.5rem"
                    }}>
                        <p>Assigned to: </p>
                        <ul>
                            {composition.licenses.map(license =>
                                <li key={license.licenseId}>
                                    <Typography variant="body1">
                                        {license.clientName}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {license.licenseDescription}
                                    </Typography>
                                </li>
                            )}
                        </ul>
                    </Box>
                }

                <Box sx={{
                    display: "flex",
                    flexGrow: 1,
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    maxWidth: "100%",
                }}>
                    {composition.tags.sort((a, b) => a.localeCompare(b)).map(tag =>
                        <Chip label={tag} key={tag} size="small" sx={{ m: "0.25rem" }} />
                    )}
                </Box>
            </Box>
            <CardMedia
                component="img"
                width="300"
                image={composition.compositionThumbnailUrl}
                style={{ filter: !composition.enabled ? "grayscale(100%)" : "grayscale(0)" }}
            />
        </Card>
    </>;
}