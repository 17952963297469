import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../AuthConfig';
import LogoImage from '../assets/images/logo_wordmark_small.png';
import './Login.scss';
import { Button } from '@mui/material';

export const Login = (): JSX.Element => {
    const { instance } = useMsal();

    return <div className="login">
        <div className="login-container">
            <div className="logo-container">
                <img src={LogoImage} className="wordmark" />
            </div>
            <Button
                onClick={() => instance.loginRedirect(loginRequest)}>Sign in
            </Button>
        </div>
    </div>
}