import AddIcon from '@mui/icons-material/Add';
import { Container, DialogContent, Fab, Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useConfirm } from 'material-ui-confirm';
import { useEffect, useState } from 'react';
import {
    addComposition as addCompositionApi,
    Composition,
    CompositionChannel,
    deleteComposition as deleteCompositionApi,
    getCompositions as getCompositionsApi,
    updateComposition as updateCompositionApi,
    updateCompositionAssignedTo as updateCompositionAssignedToApi
} from '../api/SingularCompositions';
import SingularLogo from '../assets/images/logo_singular.png';
import { CompositionCard } from '../components/CompositionCard';
import { DefaultDialog } from '../components/dialogs/DefaultDialog';
import { CompositionForm } from '../components/forms/CompositionForm';
import { sortByName } from '../utils/SortUtils';
import { License, getLicenses as getLicensesApi } from '../api/Licenses';
import { LicenseSelect } from '../components/LicenseSelect';

type Props = {
    channel: Composition["channel"]
}

export const sortLicenseByClientName = (a: License, b: License) => a.client.fullName.localeCompare(b.client.fullName);

export const Singular = ({ channel }: Props): JSX.Element => {
    const [allCompositions, setAllCompositions] = useState<Composition[]>([]);
    const [allLicenses, setAllLicenses] = useState<License[]>([]);
    const [compositions, setCompositions] = useState<Composition[]>([]);
    const [openAddComposition, setOpenAddComposition] = useState(false);
    const [editComposition, setEditComposition] = useState<Composition>(null);
    const [assignToComposition, setAssignToComposition] = useState<Composition>(null);
    const confirm = useConfirm();

    const handleAddOpen = () => { setEditComposition(null); setOpenAddComposition(true) };
    const handleAddClose = () => setOpenAddComposition(false);
    const handleAssignToOpen = (composition: Composition) => setAssignToComposition(composition);
    const handleAssignToClose = () => setAssignToComposition(null);

    useEffect(() => {
        getLicensesApi()
            .then((res) => setAllLicenses(res.data.sort(sortLicenseByClientName)));
        getCompositionsApi()
            .then((res) => setAllCompositions(res.data));
    }, []);

    useEffect(() => {
        const newCompositions = allCompositions.filter(c => c.channel === channel);
        setCompositions(newCompositions.sort(sortByName));
    }, [allCompositions, channel]);

    const handleEnabledChange = (composition: Composition, enabled: boolean) => {
        composition.enabled = enabled;

        updateCompositionApi(composition)
            .then(res => setAllCompositions(allCompositions.map((comp) => comp.id == res.data.id ? res.data : comp)));
    };

    const handleEditComposition = (composition: Composition) => {
        setEditComposition(composition);
        setOpenAddComposition(true);
    };

    const OnCompositionSubmit = (newComposition: Composition) => {
        if (editComposition) {
            updateCompositionApi(newComposition)
                .then(res => {
                    setAllCompositions(allCompositions.map((comp) => comp.id == res.data.id ? res.data : comp));
                    handleAddClose();
                });
        }
        else {
            addCompositionApi(newComposition)
                .then(res => {
                    setAllCompositions([...allCompositions, res.data]);
                    handleAddClose();
                });
        }
    }

    const handleDeleteComposition = (composition: Composition) => {
        confirm({ title: <>Delete composition <strong>{composition.name}</strong></>, description: 'Are you sure you want to delete this composition? This cannot be reverted.' })
            .then(() => deleteCompositionApi(composition))
            .then(() => setAllCompositions(allCompositions.filter(comp => comp.id !== composition.id)))
            .catch(() => null);
    }

    const handleCompositionAssignedToChange = (composition: Composition, selection: number[]) => {
        updateCompositionAssignedToApi(composition, selection)
            .then(() => {
                const update: Composition["licenses"] = allLicenses
                    .filter(lic => selection.indexOf(lic.id) !== -1)
                    .map(lic => ({
                        licenseId: lic.id,
                        licenseValue: lic.value,
                        licenseDescription: lic.description,
                        clientName: lic.client.fullName
                    }));

                composition.licenses = update;

                setAllCompositions(allCompositions.filter(comp => comp.id !== composition.id).concat([composition]));
            })
            .finally(handleAssignToClose);
    }

    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
                <Paper sx={{ p: 2 }} elevation={3}>
                    <img src={SingularLogo} style={{ height: 120, marginTop: -40, marginRight: -20, marginBottom: -40, marginLeft: -20 }} />

                    <Typography variant="h6" color="primary">
                        {CompositionChannel[channel]} compositions
                    </Typography>

                    <Grid container spacing={4} mt={0}>
                        {compositions.map((composition) => (
                            <Grid key={composition.id} item xs={12} sm={6} md={3} style={{ display: "flex" }}>
                                <CompositionCard
                                    composition={composition}
                                    onEnable={handleEnabledChange}
                                    onEdit={handleEditComposition}
                                    onAssign={handleAssignToOpen}
                                    onDelete={handleDeleteComposition} />
                            </Grid>
                        ))}
                    </Grid>
                </Paper>

                <Fab
                    color="primary"
                    className="floating-action-button"
                    aria-label="add"
                    onClick={handleAddOpen}>
                    <AddIcon />
                </Fab>
            </Container>

            <DefaultDialog
                open={openAddComposition}
                onClose={handleAddClose}
                title={editComposition ? "Edit composition" : "New composition"}>
                <DialogContent>
                    <CompositionForm
                        editComposition={editComposition}
                        onSubmit={OnCompositionSubmit} />
                </DialogContent>
            </DefaultDialog>

            <DefaultDialog
                open={!!assignToComposition}
                onClose={handleAssignToClose}
                title={<>Assign license(s) to <b>{assignToComposition?.name}</b></>}>
                <DialogContent>
                    <LicenseSelect
                        licenses={allLicenses}
                        initialSelection={assignToComposition?.licenses.map(lic => lic.licenseId) ?? []}
                        onSubmit={(selection) => handleCompositionAssignedToChange(assignToComposition, selection)}
                    />
                </DialogContent>
            </DefaultDialog>
        </>
    )
}
