import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Autocomplete, Button, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, Snackbar, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, FieldArrayWithId, FieldError, useFieldArray, useForm } from 'react-hook-form';
import { ApplicationModule, getApplicationModules as getApplicationModulesApi } from '../../api/ApplicationModules';
import { Application } from '../../api/Applications';
import { Client } from '../../api/Clients';
import { addLicense as addLicenseApi, License, LicenseEnabledAppModule, updateLicense } from '../../api/Licenses';
import { DefaultFooter } from '../DefaultFooter';
import { LicenseRender } from '../LicenseRender';

type FormValues = License;
type Props = {
    applicationsMap: Map<Application["id"], Application>;
    clientsMap: Map<Client["id"], Client>;
    editLicense?: License;
    onLicenseAdded: (client: License) => void;
    onLicenseUpdated: (license: License) => void;
};

const defaults: License = {
    applicationId: "",
    clientId: null,
    description: "",
    expires: new Date(2050, 0),
    enabledModules: []
};

const applicationModuleDefaults: LicenseEnabledAppModule = {
    applicationModuleId: null,
    validFrom: new Date(),
    validTo: new Date(),
}

/**
 * Ensures the date is actually a date instead of a string.
 * 
 * @param value string or date.
 * @returns 
 */
const dateTypeGuard = (value: string | Date): Date =>
    typeof value === "string" ? parseISO(value) : value;

export const AddLicenseForm = ({
    applicationsMap,
    clientsMap,
    editLicense,
    onLicenseAdded,
    onLicenseUpdated
}: Props): JSX.Element => {
    const { control, formState: { errors, isDirty }, handleSubmit, reset, setValue, watch } = useForm<FormValues>({ defaultValues: defaults });
    const [toastMessage, setToastMessage] = useState<string>(null);
    const [applicationModules, setApplicationModules] = useState<ApplicationModule[]>([]);
    const { fields, append, remove } = useFieldArray({ control, name: "enabledModules" });

    const submit = (license: License) => {
        if (editLicense)
            updateLicense(license)
                .then((res) => {
                    onLicenseUpdated(res.data);
                    setToastMessage("Successfully updated license");
                });
        else
            addLicenseApi(license)
                .then((res) => {
                    onLicenseAdded(res.data);
                    setToastMessage("Successfully added license");
                });
    }

    useEffect(() => {
        if (editLicense != null)
            reset(editLicense);
        else
            reset();

    }, [editLicense]);

    useEffect(() => {
        getApplicationModulesApi()
            .then((res) => setApplicationModules(res.data));
    }, []);

    const addApplicationModule = (applicationModule: ApplicationModule) => {
        append({ ...applicationModuleDefaults, applicationModuleId: applicationModule.id });
    }

    const renderErrorText = (fieldError: FieldError) => {
        switch (fieldError.type) {
            case "required":
                return "This field is required";
            case "minLength":
                return "At least one serial number is required";
            default:
                return "";
        }
    }

    const renderApplicationModuleOptions = (applicationModule: ApplicationModule, item: FieldArrayWithId<License, "enabledModules", "id">, index: number): JSX.Element => {
        switch (item.applicationModuleId) {
            case "b7b96726-7a6b-45a3-cc26-08dcd3448502":
                {
                    const typeSet = watch(`enabledModules.${index}.moduleOptions.$type`);

                    if (!typeSet)
                        setValue(`enabledModules.${index}.moduleOptions`, { $type: "ModuleOptionsSDPlugin", serialNumbers: [] });

                    return (
                        <Controller
                            control={control}
                            rules={{ required: true, minLength: 6 }}
                            name={`enabledModules.${index}.moduleOptions.serialNumbers`}
                            render={({ field, fieldState }) => (
                                <FormControl fullWidth size="small" sx={{ my: 2 }}>
                                    <TextField
                                        {...field}
                                        id="serialNumbers"
                                        label="Serial numbers"
                                        variant="outlined"
                                        error={!!fieldState.error}
                                        onChange={(e) => field.onChange(e.target.value.split(','))}
                                        helperText={fieldState.error ? renderErrorText(fieldState.error) : "Enter comma-separated values"}
                                    />
                                </FormControl>
                            )}
                        />
                    )
                }
            default:
                return <></>;
        }
    };

    return <form onSubmit={handleSubmit(submit)}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
                {editLicense?.value &&
                    <Grid item xs={12}>License key:&nbsp;
                        <LicenseRender value={editLicense.value} />
                    </Grid>
                }

                <Grid container item>
                    <Grid item xs={12} sm={10}>
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="clientId"
                                rules={{ required: true }}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        clearOnEscape
                                        size="small"
                                        options={[...clientsMap].map(([, client]) => ({ id: client.id, label: client.fullName }))}
                                        onChange={(e, data) => {
                                            if (typeof data !== 'string')
                                                onChange(data?.id ?? null);
                                        }}
                                        value={{ id: value, label: clientsMap.get(value)?.fullName ?? "" }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) =>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                label="Client"
                                                error={!!errors.clientId}
                                                {...params}
                                            />
                                        }
                                    />
                                )}
                            />
                            {errors.clientId?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid item xs={12} sm={10}>
                        <FormControl fullWidth size="small">
                            <InputLabel>Application</InputLabel>
                            <Controller
                                control={control}
                                name="applicationId"
                                rules={{ required: true }}
                                render={({ field: { onChange, ...restField } }) => (
                                    <Select
                                        label="Application"
                                        {...restField}
                                        error={!!errors.applicationId}
                                        disabled={!!editLicense}
                                        onChange={(e) => onChange(e.target.value)}
                                    >
                                        {[...applicationsMap].map(([, application]) =>
                                            <MenuItem key={application.id} value={application.id}>{application.name}</MenuItem>
                                        )}
                                    </Select>
                                )}
                            />
                            {errors.applicationId?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid item xs={12} sm={10}>
                        <FormControl fullWidth size="small">
                            <Controller
                                control={control}
                                name="description"
                                render={({ field }) => (
                                    <OutlinedInput
                                        label="Description"
                                        placeholder="Optional description e.g. 'zolderkamer'"
                                        autoComplete="off"
                                        {...field} />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid item xs={12} sm={8} md={6}>
                        <FormControl fullWidth size="small">
                            <Controller
                                control={control}
                                name="expires"
                                render={({ field }) => (
                                    <DatePicker
                                        label="Expires"
                                        onChange={(date: Date) => field.onChange(date)}
                                        value={dateTypeGuard(field.value)}
                                        slotProps={{ textField: { variant: 'outlined' } }}
                                    />
                                )}
                            />

                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid item>
                        <Typography variant="h6" component="h3">Application modules:</Typography>
                    </Grid>
                    {applicationModules.map((applicationModule, index) =>
                        <Grid
                            key={applicationModule.id}
                            container
                            item
                            rowSpacing={1}
                            ml={2}>

                            {index > 0 &&
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: 2 }} />
                                </Grid>
                            }

                            <Grid item container justifyContent="space-between" alignItems="center">
                                <Typography>
                                    {applicationModule.name}
                                </Typography>

                                <IconButton onClick={() => addApplicationModule(applicationModule)}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Grid>

                            {fields.filter(field => field.applicationModuleId === applicationModule.id).map((item) => {
                                const index = fields.indexOf(item);

                                return (
                                    <>
                                        <Grid container item spacing={2} key={item.id}>
                                            <Grid item xs={5}>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name={`enabledModules.${index}.validFrom`}
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DatePicker
                                                                label="Valid from"
                                                                onChange={(date: Date) => field.onChange(date)}
                                                                value={dateTypeGuard(field.value)}
                                                                slotProps={{ textField: { variant: 'outlined' } }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={5}>
                                                <FormControl fullWidth size="small">
                                                    <Controller
                                                        name={`enabledModules.${index}.validTo`}
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DatePicker
                                                                label="Valid to"
                                                                onChange={(date: Date) => field.onChange(date)}
                                                                value={dateTypeGuard(field.value)}
                                                                slotProps={{ textField: { variant: 'outlined' } }}
                                                            />
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <IconButton onClick={() => remove(index)}>
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        {renderApplicationModuleOptions(applicationModule, item, index)}
                                    </>
                                )
                            }
                            )}
                        </Grid>
                    )}
                </Grid>

                <Grid container item justifyContent="flex-end" xs={12}>
                    <Button disabled={!isDirty} type="submit" variant="outlined">{editLicense ? "Update" : "Create"}</Button>
                </Grid>

                {editLicense?.value &&
                    <DefaultFooter
                        createdDate={editLicense.createdDate}
                        updatedDate={editLicense.updatedDate}
                    />
                }
            </Grid>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={!!toastMessage}
                autoHideDuration={4000}
                onClose={() => setToastMessage(null)}
                message={toastMessage}
            />
        </LocalizationProvider>
    </form>
}