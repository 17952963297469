/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { initApi } from './api/AxiosConfig';
import App from './App';
import { msalConfig } from './AuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);
initApi(msalInstance);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as any).account) {
        const account = (event.payload as any).account;
        msalInstance.setActiveAccount(account);
    }
});

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
        <Router>
            <App msalInstance={msalInstance} />
        </Router>
    </React.StrictMode>
);
