import DeleteIcon from '@mui/icons-material/Delete';
import { alpha, Checkbox, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { Client } from '../api/Clients';
import { License } from '../api/Licenses';
import moment, { Moment } from "moment";
import { LicenseRender } from './LicenseRender';

type TableToolbarProps = {
    title: string;
    numResults: number;
    numSelected: number;
    onDelete: () => void;
}

const TableToolbar = ({
    title,
    numResults,
    numSelected,
    onDelete
}: TableToolbarProps): JSX.Element => {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Grid container>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="subtitle1"
                        id="tableTitle"
                        component="div"
                    >
                        {`${numResults} ${numResults == 1 ? "result" : "results"}`}
                    </Typography>
                </Grid>
            )}

            {numSelected > 0
                ? <Tooltip title="Delete">
                    <IconButton onClick={onDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                : null
            }
        </Toolbar>
    );
};

type Props = {
    title: string;
    clients: Map<Client["id"], Client>,
    licenses: License[];
    onDelete: (licenses: License[]) => Promise<unknown>;
    onEdit: (license: License) => void;
}

export const LicenseTable = ({ title, clients, licenses, onDelete, onEdit }: Props): JSX.Element => {
    const [selected, setSelected] = useState<number[]>([]);

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        event.stopPropagation();
        event.preventDefault();

        const selectedIndex = selected.indexOf(id);
        let newSelected: number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleRowClick = (_event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, license: License) => {
        onEdit(license);
    }

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = licenses.map((l) => l.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const deleteLicenses = () => {
        onDelete(licenses.filter(license => selected.indexOf(license.id) !== -1))
            .then(() => setSelected([]));
    }

    const numSelected = selected.length;
    const rowCount = licenses.length;

    return <>
        <TableToolbar
            title={title}
            numResults={licenses.length}
            numSelected={selected.length}
            onDelete={deleteLicenses}
        />
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={handleSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />
                        </TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Client</TableCell>
                        <TableCell align="left">License Key</TableCell>
                        <TableCell align="right">Machine ID</TableCell>
                        <TableCell align="right">Description</TableCell>
                        <TableCell align="right">Created</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {licenses.map((license) => {
                        const isItemSelected = selected.indexOf(license.id) !== -1;

                        return <TableRow
                            key={license.value}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            hover
                            onClick={(event) => handleRowClick(event, license)}
                        >
                            <TableCell padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    onClick={(event) => handleClick(event, license.id)}
                                />
                            </TableCell>
                            <TableCell align="left">{license.id}</TableCell>
                            <TableCell align="left">{clients.get(license.clientId)?.fullName}</TableCell>
                            <TableCell component="th" scope="row"><LicenseRender value={license.value} /></TableCell>
                            <TableCell align="right">{license.machineId}</TableCell>
                            <TableCell align="right">{license.description}</TableCell>
                            <TableCell align="right">{moment(license.createdDate).local().fromNow()}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}