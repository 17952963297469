import axios, { AxiosResponse } from "axios";
import { DefaultModel } from "./DefaultModel";

const url = "applicationmodules";

export interface ApplicationModule extends DefaultModel {
    id: string;
    name: string;
    optionsSchema?: string;
}

export const getApplicationModules = (): Promise<AxiosResponse<ApplicationModule[]>> =>
    axios.get<ApplicationModule[]>(url);

export const addApplicationModule = (applicationModule: ApplicationModule): Promise<AxiosResponse<ApplicationModule>> =>
    axios.post<ApplicationModule>(url, applicationModule);

export const updateApplicationModule = (applicationModule: ApplicationModule): Promise<AxiosResponse<ApplicationModule>> =>
    axios.put<ApplicationModule>(`${url}/${applicationModule.id}`, applicationModule);