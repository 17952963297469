import { useMsal } from '@azure/msal-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import { AppBar, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import clsx from 'clsx';
import { MouseEvent, PropsWithChildren, useState } from 'react';
import { AppRoles } from '../../AuthConfig';
import './NavBar.scss';
import { NavigationLink } from './NavigationLink';

type Props = PropsWithChildren<object>;


export const NavBar = ({ children }: Props): JSX.Element => {
    const [open, setOpen] = useState(true);
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>(null);
    const { instance } = useMsal();

    const userHasRole = (appRole: AppRoles): boolean => {
        const currentAccount = instance?.getActiveAccount();

        return currentAccount?.idTokenClaims['roles']?.includes(appRole);
    };

    const mayAccessApplications = userHasRole(AppRoles.ApplicationWrite);
    const accountMenuOpen = !!anchorEl;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
        if (!event?.currentTarget) return;

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        instance.logoutRedirect();
    };

    return (
        <Box className={`root ${clsx(open && "app-bar-open")}`}>
            <CssBaseline />
            <AppBar className={`app-bar`}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        style={{ display: open ? "none" : "inline-flex" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className="title" noWrap>
                        Licensing Area
                    </Typography>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={accountMenuOpen}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={logout}>Sign out</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Drawer
                className="drawer"
                variant="persistent"
                anchor="left"
                open={open}
            >
                <div className="drawer-header">
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />

                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <NavigationLink
                                text="Dashboard"
                                to="/"
                                iconElement={<DashboardIcon />}
                            />
                        </List>

                        <Divider />

                        <List>
                            <NavigationLink
                                text="Clients"
                                to="/clients"
                                iconElement={<PeopleIcon />}
                            />

                            <NavigationLink
                                text="Licenses"
                                to="/licenses"
                                iconElement={<ListAltIcon />}
                            />

                            {mayAccessApplications &&
                                <NavigationLink
                                    text="Applications"
                                    to="/applications"
                                    iconElement={<AppRegistrationIcon />}
                                />
                            }
                        </List>

                        <Divider />

                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AppRegistrationIcon />
                                </ListItemIcon>
                                <ListItemText primary="Singular" />
                            </ListItem>
                        </List>
                        <List sx={{ pl: 4, mb: 0 }} disablePadding>
                            <NavigationLink
                                text="Public"
                                to="/singular"
                                iconElement={<PublicIcon />}
                            />
                            <NavigationLink
                                text="Custom"
                                to="/singular/custom"
                                iconElement={<LockPersonIcon />}
                            />
                        </List>

                        <Divider />

                    </nav>
                </Box>
            </Drawer>

            <div className="drawer-spacer" />
            <div className="main" >
                {children}
            </div>
        </Box>
    );
}