import { PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { createTheme, ThemeProvider } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { NavBar } from './components/layout/NavBar';
import { Applications, Clients, Home, Login, Singular } from './pages';
import { Licenses } from './pages/Licenses';
import { CompositionChannel } from './api/SingularCompositions';

const darkTheme = createTheme({
    palette: {
        mode: 'light',
    }
});

const RenderContent = (): JSX.Element => {
    return (
        <div className="app">
            <AuthenticatedTemplate>
                <NavBar>
                    <Routes>
                        <Route path="/clients" element={<Clients />} />
                        <Route path="/licenses" element={<Licenses />} />
                        <Route path="/users" element={<Home />} />
                        <Route path="/applications" element={<Applications />} />
                        <Route path="/singular" element={<Singular channel={CompositionChannel.Public} />} />
                        <Route path="/singular/custom" element={<Singular channel={CompositionChannel.Private} />} />
                        <Route path="/" element={<Home />} />
                    </Routes>
                </NavBar>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </div>
    );
};

type Props = {
    msalInstance: PublicClientApplication
}

const App = ({ msalInstance }: Props): JSX.Element => (
    <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={darkTheme}>
            <ConfirmProvider>
                <RenderContent />
            </ConfirmProvider>
        </ThemeProvider>
    </MsalProvider>
);

export default App;