import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Button, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Fab from '@mui/material/Fab';
import { useEffect, useState } from 'react';
import { addApplicationModule as addApplicationModuleApi, ApplicationModule, getApplicationModules as getApplicationModulesApi, updateApplicationModule as updateApplicationModuleApi } from '../api/ApplicationModules';
import { addApplication, Application, getApplications } from '../api/Applications';
import { ApplicationCard } from '../components/ApplicationCard';
import { ApplicationModuleCard } from '../components/ApplicationModuleCard';
import { DefaultDialog } from '../components/dialogs/DefaultDialog';
import { AddApplicationForm } from '../components/forms/ApplicationForm';
import { ApplicationModuleForm } from '../components/forms/ApplicationModuleForm';
import { sortByName } from '../utils/SortUtils';

export const Applications = (): JSX.Element => {
    const [applications, setApplications] = useState<Application[]>([]);
    const [applicationModules, setApplicationModulesSorted] = useState<ApplicationModule[]>([]);
    const [open, setOpen] = useState(false);
    const [addModuleOpen, setAddModuleOpen] = useState<boolean>(false);
    const [editApplicationModule, setEditApplicationModule] = useState<ApplicationModule>();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenAddModule = () => {
        setAddModuleOpen(true);
    };

    const handleCloseAddModule = () => {
        setEditApplicationModule(null);
        setAddModuleOpen(false);
    };

    const setApplicationModules = (applicationModules: ApplicationModule[]) => setApplicationModulesSorted(applicationModules?.sort(sortByName))

    useEffect(() => {
        getApplicationModulesApi()
            .then((res) => setApplicationModules(res.data));

        getApplications()
            .then((res) => setApplications(res.data));
    }, []);

    const onAddApplicationSubmit = (appDetails: Application) => {
        addApplication(appDetails)
            .then((response) => {
                setApplications([...applications, response.data]);
                setOpen(false);
            });
    }

    const onEditApplicationModule = (applicationModule: ApplicationModule) => {
        setEditApplicationModule(applicationModule);
        setAddModuleOpen(true);
    }

    const onAddApplicationModuleSubmit = (applicationModule: ApplicationModule) => {
        if (editApplicationModule)
            updateApplicationModuleApi(applicationModule)
                .then((res) => {
                    setApplicationModules(applicationModules.filter(appModule => appModule.id !== applicationModule.id).concat(res.data));
                    setAddModuleOpen(false);
                });
        else
            addApplicationModuleApi(applicationModule)
                .then((res) => {
                    setApplicationModules([...applicationModules, res.data]);
                    setAddModuleOpen(false);
                });
    }

    return <>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    m={2}
                >
                    <Typography component="h2" variant="h5" color="primary">
                        Applications
                    </Typography>
                </Stack>

                <Grid container spacing={2}>
                    {applications.map((application) =>
                        <Grid
                            key={application.id}
                            item
                            xs={4}>
                            <ApplicationCard
                                application={application} />
                        </Grid>
                    )}
                </Grid>
            </Paper>

            <Paper elevation={3} sx={{ p: 2, my: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    m={2}
                >
                    <Typography component="h2" variant="h5" color="primary">
                        Application modules
                    </Typography>

                    <Button
                        variant="contained"
                        startIcon={<PlaylistAddIcon />}
                        onClick={handleOpenAddModule}>Add module
                    </Button>
                </Stack>

                <Grid container spacing={2}>
                    {applicationModules.map((applicationModule) =>
                        <Grid
                            key={applicationModule.id}
                            item
                            xs={4}>
                            <ApplicationModuleCard
                                applicationModule={applicationModule}
                                onEdit={onEditApplicationModule} />
                        </Grid>
                    )}
                </Grid>
            </Paper>

            <Fab color="primary" className="floating-action-button" onClick={handleOpen}>
                <AddIcon />
            </Fab>
        </Container>

        <DefaultDialog
            open={!!addModuleOpen}
            onClose={handleCloseAddModule}
            title={editApplicationModule ? "Edit application module" : "Add application module"}>
            <DialogContent>
                <ApplicationModuleForm
                    editApplicationModule={editApplicationModule}
                    onSubmit={onAddApplicationModuleSubmit} />
            </DialogContent>
        </DefaultDialog>

        <DefaultDialog
            open={open}
            onClose={handleClose}
            title="New Application">
            <DialogContent>
                <AddApplicationForm
                    onSubmit={(appDetails) => onAddApplicationSubmit(appDetails)} />
            </DialogContent>
        </DefaultDialog>
    </>
}