import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Card, CardContent, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { ApplicationModule } from '../api/ApplicationModules';

type Props = {
    applicationModule: ApplicationModule;
    onEdit: (applicationModule: ApplicationModule) => void;
};

export const ApplicationModuleCard = ({ applicationModule, onEdit }: Props): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: React.MouseEvent) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleOnEdit = () => {
        onEdit(applicationModule);
        handleClose();
    }

    return <>
        <Card
            elevation={4}
        >
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" component="div">
                        {applicationModule.name}
                    </Typography>

                    <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={handleOnEdit}>Edit module</MenuItem>
                    </Menu>
                </Stack>

                <Typography sx={{ mt: 1.5, fontWeight: "bold" }}>
                    Module ID:
                </Typography>
                <Typography color="text.secondary">
                    {applicationModule.id}
                </Typography>

            </CardContent>
        </Card>
    </>
};