import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Snackbar } from '@mui/material';
import React, { useState } from 'react';

type Props = { value: string };

export const LicenseRender = ({ value }: Props): JSX.Element => {
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();

        navigator.clipboard.writeText(value)
            .then(() => setShowSnackbar(true));
    }

    return <>{value}
        <span style={{ margin: "0.5rem" }} />
        <IconButton onClick={handleClick} sx={{ m: -2 }}>
            <ContentCopyIcon fontSize="small" />
        </IconButton>

        <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={showSnackbar}
            autoHideDuration={4000}
            onClose={() => setShowSnackbar(false)}
            message={"License is copied to your clipboard"}
        />
    </>
}