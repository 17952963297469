import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Container, Grid, IconButton, Paper, Snackbar, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect, useState } from 'react';
import { addClient, Client, ClientDetails as CLientDetailsType, deleteClient as deleteClientApi, getClients, updateClient } from '../api/Clients';
import { ClientDetails } from '../components/ClientDetails';
import { DefaultDialog } from '../components/dialogs/DefaultDialog';
import { AddClientForm } from '../components/forms/ClientForm';

export const Clients = (): JSX.Element => {
    const [clients, setClientsData] = useState<Client[]>([]);
    const [openAddClient, setOpenAddClient] = useState(false);
    const [successToastOpen, setSuccessToastOpen] = useState(false);
    const [editClient, setEditClient] = useState<Client>(null);
    const confirm = useConfirm();

    const handleAddOpen = () => setOpenAddClient(true);

    const handleAddClose = () => {
        setOpenAddClient(false);
        setEditClient(null);
    };

    const setClients = (clients: Client[]) => {
        clients.sort((a, b) => a.fullName.localeCompare(b.fullName));

        setClientsData(clients);
    }

    const onAddClientSubmit = (client: CLientDetailsType) => {
        addClient(client)
            .then((response) => {
                setClients([...clients, response.data]);
                handleAddClose();
                setSuccessToastOpen(true);
            });
    }

    const onUpdateClientSubmit = (client: CLientDetailsType) => {
        updateClient(client)
            .then((response) => {
                const index = clients.findIndex(existingClient => existingClient.id === client.id);
                clients[index] = response.data;
                setClients([...clients]);
                handleAddClose();
            });
    }

    useEffect(() => {
        getClients()
            .then((res) => setClients(res.data))
            .catch((error) => console.error(error));
    }, []);

    const renderClientAddedSnackbar = (): JSX.Element => {
        return <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            disableWindowBlurListener
            open={successToastOpen}
            autoHideDuration={6000}
            onClose={() => setSuccessToastOpen(false)}
        >
            <Alert onClose={handleAddClose} severity="success" sx={{ width: '100%' }}>
                Client successfully added
            </Alert>
        </Snackbar>
    }

    const renderClientInfo = (client: Client): JSX.Element => {
        const onEditClient = (event: React.MouseEvent<HTMLButtonElement>, client: Client) => {
            event.stopPropagation();

            setEditClient(client);
            setOpenAddClient(true);
        };

        const onDeleteClient = (event: React.MouseEvent<HTMLButtonElement>, client: Client) => {
            event.stopPropagation();

            confirm({ title: `Delete ${client.fullName}`, description: 'Are you sure you want to delete this client? All assigned licenses will be removed.' })
                .then(() => deleteClient(client.id))
                .catch(() => null);
        };

        const deleteClient = (clientId: number) => {
            deleteClientApi(clientId)
                .then(() => setClients(clients.filter(client => client.id !== clientId)));
        }

        return <Accordion
            key={client.id}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Grid container
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography>{client.fullName}</Typography>

                    <Grid
                        item
                        container
                        flex={1}
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <IconButton onClick={(event) => onEditClient(event, client)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={(event) => onDeleteClient(event, client)}>
                            <DeleteForeverIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </AccordionSummary>

            <AccordionDetails>
                <ClientDetails
                    clientId={client.id}
                />
            </AccordionDetails>

        </Accordion>
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Paper elevation={3} sx={{ p: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    m={2}
                >
                    <Typography component="h2" variant="h6" color="primary">
                        Clients
                    </Typography>

                    <Button
                        variant="contained"
                        startIcon={<PersonAddIcon />}
                        onClick={handleAddOpen}
                    >
                        Add client
                    </Button>

                </Stack>

                <div>
                    {clients.map((client) => {
                        return renderClientInfo(client);
                    })}
                </div>

                <DefaultDialog
                    open={openAddClient}
                    onClose={handleAddClose}
                    title={editClient ? "Edit client" : "New client"}>
                    <DialogContent>
                        <AddClientForm
                            editClientId={editClient?.id}
                            onSubmit={editClient ? onUpdateClientSubmit : onAddClientSubmit} />
                    </DialogContent>
                </DefaultDialog>

                {renderClientAddedSnackbar()}
            </Paper>
        </Container>
    );
}