import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-common";
import axios from "axios";
import { silentRequest } from "../AuthConfig";

export const initApi = (instance: PublicClientApplication): void => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

    axios.interceptors.request.use(async (config) => {
        const accessTokenRequest = {
            scopes: [...silentRequest.scopes],
            account: instance.getActiveAccount()
        }

        try {
            const { accessToken } = await instance.acquireTokenSilent(accessTokenRequest);

            config.headers["Authorization"] = "Bearer " + accessToken;

            return config;
        }
        catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    const { accessToken } = await instance.acquireTokenPopup(accessTokenRequest);

                    config.headers["Authorization"] = "Bearer " + accessToken;

                    return config;
                }
                catch (error) {
                    console.error(error);
                }
            }
            console.error(error);
        }
    })
};
