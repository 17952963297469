import moment from 'moment';
import { useState } from 'react';

type Props = {
    createdDate: Date;
    updatedDate: Date;
}

export const DefaultFooter = ({ createdDate, updatedDate }: Props): JSX.Element => {
    const [showExactCreated, setShowExactCreated] = useState(false);
    const [showExactUpdated, setShowExactUpdated] = useState(false);

    const createdMoment = moment(createdDate);
    const updatedMoment = moment(updatedDate);

    return <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", fontSize: "0.75rem", marginTop: "1rem", marginLeft: "1rem" }}>
        <span onClick={() => setShowExactCreated(!showExactCreated)}>Created: {showExactCreated ? createdMoment.format('llll') : createdMoment.fromNow()}</span>
        <span onClick={() => setShowExactUpdated(!showExactUpdated)}>Updated: {showExactUpdated ? updatedMoment.format('llll') : updatedMoment.fromNow()}</span>
    </div>
};