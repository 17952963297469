import axios, { AxiosResponse } from "axios";
import { LicenseDetails } from "./Licenses";

const url = "clients";

export type Client = {
    id?: number;
    fullName: string;
}

export type ClientDetails = Client & {
    name: string;
    department?: string;
    address: Address;
    licenses: LicenseDetails[];
}

export type Address = {
    streetAddress: string;
    postalCode: string;
    city: string;
    country: string;
}

export const getClients = (): Promise<AxiosResponse<Client[]>> =>
    axios.get<Client[]>(url);

export const addClient = (client: ClientDetails): Promise<AxiosResponse<ClientDetails>> =>
    axios.post<ClientDetails>(url, client);

export const updateClient = (client: ClientDetails): Promise<AxiosResponse<ClientDetails>> =>
    axios.put<ClientDetails>(`${url}/${client.id}`, client);

export const deleteClient = (clientId: number): Promise<AxiosResponse<ClientDetails>> =>
    axios.delete<ClientDetails>(`${url}/${clientId}`);

export const getClientDetails = (id: number): Promise<AxiosResponse<ClientDetails>> =>
    axios.get<ClientDetails>(`${url}/${id}`);