import { Autocomplete, Button, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Composition, CompositionChannel, getCompositionTags } from '../../api/SingularCompositions';
import { DefaultFooter } from '../DefaultFooter';

type Props = {
    editComposition?: Composition;
    onSubmit: (composition: Composition) => void;
}

const defaults: Partial<Composition> = {
    id: null,
    name: "",
    compositionId: null,
    compositionAppTemplateId: null,
    compositionThumbnailUrl: "",
    compositionVideoPreviewUrl: "",
    channel: CompositionChannel.Public,
    tags: []
};

export const CompositionForm = ({ editComposition, onSubmit }: Props): JSX.Element => {
    const { control, register, handleSubmit, formState: { errors, isDirty }, reset } = useForm<Composition>({ defaultValues: defaults });
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        getCompositionTags()
            .then(res => setTags(res.data.sort((a, b) => a.localeCompare(b))))
            .catch(error => console.error(error));

        if (editComposition != null)
            reset(editComposition);
        else
            reset();
    }, [editComposition]);

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
            <Grid item xs={7}>
                <FormControl fullWidth>
                    <TextField {...register("name", { required: true })}
                        id="compositionName"
                        label="Name *"
                        size="small"
                        error={!!errors.name} />
                    {errors.name?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Controller
                        control={control}
                        name="tags"
                        render={({ field: { onChange, value } }) => (
                            <Autocomplete
                                multiple
                                id="tags-input"
                                freeSolo
                                filterSelectedOptions
                                value={value}
                                options={tags}
                                onChange={(e, data) => onChange(data)}
                                renderTags={(tagValue: readonly string[], getTagProps) =>
                                    tagValue.map((option: string, index: number) => (
                                        <Chip label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tags"
                                        placeholder="Tags"
                                        size="small"
                                    />
                                )}
                            />
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid container item>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField  {...register("compositionId", { required: true })}
                            id="compositionId"
                            label="Composition ID *"
                            size="small"
                            type="number"
                            error={!!errors.id} />
                        {errors.compositionId?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container item>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField  {...register("compositionAppTemplateId", { required: true })}
                            id="compositionAppTemplateId"
                            label="App Template ID *"
                            size="small"
                            type="number"
                            error={!!errors.name} />
                        {errors.compositionAppTemplateId?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField  {...register("compositionThumbnailUrl", { required: true })}
                        id="compositionThumbnailUrl"
                        label="Thumbnail URL *"
                        size="small"
                        type="url"
                        error={!!errors.compositionThumbnailUrl} />
                    {errors.compositionThumbnailUrl?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField  {...register("compositionVideoPreviewUrl", { required: true })}
                        id="compositionVideoPreviewUrl"
                        label="Video preview URL *"
                        size="small"
                        type="url"
                        error={!!errors.compositionVideoPreviewUrl} />
                    {errors.compositionVideoPreviewUrl?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth size="small">
                    <InputLabel>Channel</InputLabel>
                    <Controller
                        control={control}
                        name="channel"
                        rules={{ required: true }}
                        render={({ field: { onChange, ...restField } }) => (
                            <Select
                                label="Channel"
                                error={!!errors.channel}
                                onChange={(e) => onChange(Number(e.target.value) as CompositionChannel)}
                                {...restField}
                            >
                                {Object.keys(CompositionChannel).filter(c => !isNaN(Number(c))).map((key) =>
                                    <MenuItem key={key} value={Number(key) as CompositionChannel}>{CompositionChannel[Number(key)]}</MenuItem>
                                )}
                            </Select>
                        )}
                    />
                    {errors.channel?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid container item justifyContent="flex-end" xs={12}>
                <Button disabled={!isDirty} type="submit" variant="outlined">{editComposition ? "Update" : "Add"}</Button>
            </Grid>

            {!!editComposition &&
                <DefaultFooter createdDate={editComposition.createdDate} updatedDate={editComposition.updatedDate} />
            }
        </Grid>
    </form >
    )
}