import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { ComponentProps, PropsWithChildren } from 'react';

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const DefaultDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

type Props = PropsWithChildren<Omit<ComponentProps<typeof Dialog>, "title"> & { closeOnBackdropClick?: boolean; title?: React.ReactNode; }>;

export const DefaultDialog = (props: Props): JSX.Element => {
    const { closeOnBackdropClick: closeOnBackdropClickProp, title, ...rest } = props;
    const closeOnBackdropClick = closeOnBackdropClickProp != null ? closeOnBackdropClickProp : true;

    const handleClose = (_?: unknown, reason?: "backdropClick" | "escapeKeyDown") => {

        if (reason == "backdropClick" && !closeOnBackdropClick)
            return;

        props.onClose?.(_, reason);
    };

    return <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ marginBottom: 2 }}
        {...{ ...rest, onClose: handleClose }}
    >
        {!!title &&
            <DefaultDialogTitle id="customized-dialog-title" onClose={handleClose}>
                {title}
            </DefaultDialogTitle>
        }

        {props.children}

    </Dialog>
};