import { FormControl, FormHelperText, TextField, Grid, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { Application } from '../../api/Applications';

type FormValues = Application;
type Props = { onSubmit: (application: Application) => void };

export const AddApplicationForm = ({ onSubmit }: Props): JSX.Element => {
    const { register, handleSubmit, formState: { errors } } = useForm<FormValues>();

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
            <Grid item xs={7} >
                <FormControl fullWidth>
                    <TextField  {...register("name", { required: true })}
                        id="applicationName"
                        label="Name *"
                        size="small"
                        error={!!errors.name} />
                    {errors.name?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>
            
            <Grid container item justifyContent="flex-end" xs={12}>
                <Button type="submit" variant="outlined">Submit</Button>
            </Grid>
        </Grid>
    </form>
}