import axios, { AxiosResponse } from 'axios';
import { DefaultModel } from './DefaultModel';
import { LicenseClient } from './Licenses';

const url = "singularcompositions";

export interface Composition extends DefaultModel {
    id: number;
    enabled: boolean;
    name: string;
    compositionId: number;
    compositionAppTemplateId: number;
    compositionThumbnailUrl: string;
    compositionVideoPreviewUrl: string;
    channel: CompositionChannel;
    tags: string[];
    licenses: LicenseClient[];
}

export enum CompositionChannel {
    Public,
    Private
}

export const getCompositions = (): Promise<AxiosResponse<Composition[]>> =>
    axios.get<Composition[]>(url);

export const addComposition = (composition: Composition): Promise<AxiosResponse<Composition>> =>
    axios.post<Composition>(url, composition);

export const updateComposition = (composition: Composition): Promise<AxiosResponse<Composition>> =>
    axios.put<Composition>(`${url}/${composition.id}`, composition);

export const updateCompositionAssignedTo = (composition: Composition, licenses: number[]): Promise<AxiosResponse<Composition>> =>
    axios.put<Composition>(`${url}/assign/${composition.id}`, { licenses: licenses });

export const deleteComposition = (composition: Composition): Promise<AxiosResponse> =>
    axios.delete(`${url}/${composition.id}`);

export const getCompositionTags = (): Promise<AxiosResponse<string[]>> =>
    axios.get<string[]>(`${url}/tags`);
