import { Card, CardContent, Stack, Typography } from '@mui/material';
import { Application } from '../api/Applications';

type Props = {
    application: Application;
};

export const ApplicationCard = ({ application }: Props): JSX.Element => {

    return <>
        <Card
            sx={{ minWidth: 275 }}
            elevation={4}
        >
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography variant="h5" component="div">
                        {application.name}
                    </Typography>
                </Stack>

                <Typography sx={{ mt: 1.5, fontWeight: "bold" }}>
                    Application ID:
                </Typography>
                <Typography color="text.secondary">
                    {application.id}
                </Typography>
                <Typography sx={{ mt: 1.5, fontWeight: "bold" }}>
                    Application Public Key:
                </Typography>
                <Typography sx={{ wordBreak: "break-word" }} color="text.secondary">
                    {application.publicKey}
                </Typography>

            </CardContent>
        </Card>
    </>
};