import { CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ClientDetails as ClientDetailsType, getClientDetails } from "../api/Clients";
import { LicenseDetails as LicenseDetailsType } from "../api/Licenses";
import { LicenseDetails } from "./LicenseDetails";

type Props = {
    clientId: number;
}

export const ClientDetails = ({ clientId }: Props): JSX.Element => {
    const [clientDetails, setClientDetails] = useState<ClientDetailsType>(null);

    useEffect(() => {
        getClientDetails(clientId)
            .then((response) => setClientDetails(response.data));
    }, []);

    const renderLicenses = (licenseDetails: LicenseDetailsType[]): JSX.Element[] => {
        return licenseDetails.map((details) => (
            <LicenseDetails
                key={details.id}
                licenseDetails={details} />
        ));
    };

    if (!clientDetails) return <CircularProgress />;

    return <Grid container>
        <Grid item xs={4}>
            <Typography><b>Address: </b></Typography>
            <Typography>{clientDetails.address.streetAddress}</Typography>
            <Typography>{clientDetails.address.postalCode} {clientDetails.address.city}</Typography>
            <Typography>{clientDetails.address.country}</Typography>
        </Grid>
        <Grid item xs={8}>
            <Typography><b>Licenses: </b></Typography>
            {clientDetails?.licenses?.length
                ? renderLicenses(clientDetails?.licenses)
                : <Typography>No licenses assigned yet</Typography>
            }
        </Grid>
    </Grid>
}