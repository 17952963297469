import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink, To } from 'react-router-dom';

type Props = {
    text: string;
    iconElement: JSX.Element;
    to: To;
}

const DEFAULT_LINK_STYLE = { color: "inherit", textDecorationLine: "inherit" };

export const NavigationLink = ({ text, iconElement, to }: Props): JSX.Element => (
    <NavLink to={to} style={DEFAULT_LINK_STYLE} end>
        {({ isActive }) => (
            <ListItem disablePadding>
                <ListItemButton selected={isActive}>
                    <ListItemIcon>
                        {iconElement}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
            </ListItem>
        )}
    </NavLink>
)