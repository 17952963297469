import { FormControl, FormHelperText, TextField, Grid, Button } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ApplicationModule } from '../../api/ApplicationModules';

type FormValues = ApplicationModule;
type Props = { editApplicationModule?: ApplicationModule, onSubmit: (application: ApplicationModule) => void };

const defaults: ApplicationModule = {
    id: null,
    name: ""
};

export const ApplicationModuleForm = ({ editApplicationModule, onSubmit }: Props): JSX.Element => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm<FormValues>({ defaultValues: defaults });

    useEffect(() => {
        if (editApplicationModule != null)
            reset(editApplicationModule);
        else
            reset();
    }, [editApplicationModule]);

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
            <Grid item xs={7} >
                <FormControl fullWidth>
                    <Controller
                        control={control}
                        name="name"
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField
                                label="Name *"
                                size="small"
                                error={!!errors.name}
                                {...field} />
                        )}
                    />
                    {errors.name?.type === "required" && <FormHelperText>This field is required</FormHelperText>}
                </FormControl>
            </Grid>

            <Grid container item justifyContent="flex-end" xs={12}>
                <Button type="submit" variant="outlined">Submit</Button>
            </Grid>
        </Grid>
    </form>
}