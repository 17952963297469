import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { Application } from "./Applications";
import { Client } from "./Clients";
import { DefaultModel } from "./DefaultModel";

const url = "licenses";

export interface License extends DefaultModel {
    id?: number;
    value?: string;
    machineId?: string;
    applicationId: string;
    clientId: number;
    client?: Client;
    description?: string;
    expires: Date;
    enabledModules: LicenseEnabledAppModule[];
}

export interface LicenseEnabledAppModule extends DefaultModel {
    id?: number;
    applicationModuleId: string;
    validFrom: Date;
    validTo: Date;
    moduleOptions?: ModuleOptions;
}

export interface LicenseModuleOptions {
    $type: "ModuleOptionsDefault";
}

export interface LicenseModuleOptionsSDPlugin {
    $type: "ModuleOptionsSDPlugin";
    serialNumbers: string[];
}

export type ModuleOptions = LicenseModuleOptions | LicenseModuleOptionsSDPlugin;
export type ModuleOptionsType = ModuleOptions["$type"];

export interface LicenseClient {
    licenseId: number;
    licenseValue: string;
    licenseDescription: string;
    clientName: string;
}

export type LicenseDetails = License & {
    application: Application;
};

export const getLicenses = (): Promise<AxiosResponse<License[]>> =>
    axios.get<License[]>(url);

export const addLicense = (license: License): Promise<AxiosResponse<LicenseDetails>> => {
    const licenseUpdate = { ...license, expires: moment(license.expires).utcOffset(0, true).format() };
    licenseUpdate.enabledModules?.map(module => (
        {
            ...module,
            validFrom: moment(module.validFrom).utcOffset(0, true).format(),
            validTo: moment(module.validTo).utcOffset(0, true).format()
        }));
    return axios.post<LicenseDetails>(url, licenseUpdate);
}

export const updateLicense = (license: License): Promise<AxiosResponse<LicenseDetails>> => {
    const licenseUpdate = { ...license, expires: moment(license.expires).utcOffset(0, true).format() };
    licenseUpdate.enabledModules?.map(module => (
        {
            ...module,
            validFrom: moment(module.validFrom).utcOffset(0, true).format(),
            validTo: moment(module.validTo).utcOffset(0, true).format()
        }));
    return axios.put<LicenseDetails>(`${url}/${license.id}`, licenseUpdate);
}

export const deleteLicense = (licenseId: number): Promise<AxiosResponse<LicenseDetails>> =>
    axios.delete<LicenseDetails>(`${url}/${licenseId}`);

export const getLicenseDetails = (id: string): Promise<AxiosResponse<LicenseDetails>> =>
    axios.get<LicenseDetails>(`${url}/${id}`);
