import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { FormEvent, useEffect, useState } from 'react';
import { License } from '../api/Licenses';

type Props = {
    licenses: License[];
    initialSelection: number[];
    onSubmit: (selection: number[]) => void;
}

export const LicenseSelect = ({ licenses = [], initialSelection, onSubmit }: Props): JSX.Element => {
    const [selection, setSelection] = useState<number[]>([]);

    useEffect(() => {
        setSelection(initialSelection);
    }, [initialSelection]);

    const handleCheckboxChange = (licenseId: number, checked: boolean) => {
        if (!checked)
            setSelection(selection.filter(sel => sel !== licenseId));
        else
            setSelection([...selection, licenseId]);
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(selection);
    }

    return (
        <Box sx={{ minWidth: 540 }}>
            <form onSubmit={handleSubmit}>
                <Grid container sx={{ w: 100 }}>
                    <Grid item sx={{ maxHeight: 480, overflowY: "auto", width: "100%", mb: 2 }}>
                        <FormGroup>
                            {licenses.map(license => {
                                const checked = selection.indexOf(license.id) !== -1;

                                return <FormControlLabel
                                    key={license.id}
                                    control={<Checkbox checked={checked} />}
                                    label={
                                        <>
                                            <Typography variant="body1">
                                                {license.client.fullName}
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {license.value}
                                            </Typography>
                                        </>
                                    }
                                    onChange={(_, checked) => handleCheckboxChange(license.id, checked)}
                                    sx={{ mb: 2 }}
                                />
                            })}
                        </FormGroup>
                    </Grid>

                    <Grid item container justifyContent="flex-end">
                        <Button type="submit" variant="outlined">Update</Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}