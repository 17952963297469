import { useMsal } from "@azure/msal-react";
import { Container, Paper } from "@mui/material";

export const Home = (): JSX.Element => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    return (
        <Container maxWidth="lg" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 2, my: 2 }}>Welcome {account?.name || ""}</Paper>
        </Container>
    )
}